<template> 
<!-- <div v-if="perms.includes('Utilisateurs') || perms.includes('Admin')"> -->
    <div v-if="loading">
      <div id="loading-bg">
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
 <b-row v-else>
    <b-col
        cols="12"
        lg="6"
    >
    <b-card title="Historique des tâches" style="height: 360px; overflow: auto;">
        <app-timeline>
        <app-timeline-item   v-for="item in history" :key="item.id"
            :title="item.task"
            
            :time="getDate(item.created_at)"
            :variant="getColor()"
        />

        <!-- <app-timeline-item
            title="Last milestone remain"
            subtitle="You are just one step away from your goal"
            time="3 minutes ago"
            variant="info"
        />

        <app-timeline-item
            title="Your are running low on time"
            subtitle="Only 30 minutes left to finish milestone"
            time="21 minutes ago"
            variant="warning"
        />

        <app-timeline-item
            title="Client Meeting"
            subtitle="New event has been added to your schedule"
            time="36 minutes ago"
        />

        <app-timeline-item
            title="Product Design"
            subtitle="Product design added in workflow"
            time="1 hour ago"
            variant="secondary"
        /> -->
        </app-timeline>
    </b-card>
    </b-col>
    <b-col
        cols="12"
        lg="6"
    >
        <b-card style="height: 360px; overflow: auto;"
    no-body
  >
    <b-card-body>
      <b-card-title>Permissions</b-card-title>
      <b-card-sub-title>Permission affectées à cet utilisateur</b-card-sub-title>
    </b-card-body>
    <b-table
      striped
      responsive
      :items="PermsUser"
      class="mb-0"
    >

      <template #cell(Permission)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          disabled
          checked="true"
        />
      </template>

    </b-table>
  </b-card>
    </b-col>
 </b-row>
</template>

<script>
import config from '@/config'
import BCardCode from '@core/components/b-card-code'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BRow, BCol
} from 'bootstrap-vue'
import router from '@/router'
import moment from 'moment'; 
import 'moment/locale/fr'  // without this line it didn't work

export default {
  components: {
    BCardCode,
    AppTimeline,
    AppTimelineItem,
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BRow, BCol, 
  },
  data() {
    return {
    loading:false,
    history:[],
    PermsUser:[],
    }
  },
  mounted(){
      moment.locale('fr')
      this.loading = true
       this.$http.get(`${config.API_BASE_URL}/api/auth/HistoryUser/${router.currentRoute.params.id}`)
      .then(res => { this.history=res.data,this.loading = false 
    //   console.clear() 
      })
      this.$http.get(`${config.API_BASE_URL}/api/auth/UserPerms/${router.currentRoute.params.id}`)
      .then(res => { this.PermsUser=res.data })
    //   console.log(moment('2021-11-15 16:27:28').fromNow())
    this.getColor();
  },
  methods:{
      getDate(value){
          moment.locale('fr')
          return moment(value).fromNow();
      },
      getColor(){
        let colors = ["success", "info", "warning", "secondary", "success", "danger", "primary"];

        let random = Math.floor(Math.random() * colors.length);
        return colors[random];
      }
  },
    setup() {
    const permissionsData = [
      {
        Permission: 'Admin',
        Affectée: true,
        
      },
      {
        Permission: 'Facturation Fournisseurs',
        Affectée: false,
        
      },
      {
        Permission: 'Facturation Clients',
        Affectée: true,
        
      },
      {
        Permission: 'Produits & Stock',
        Affectée: false,
        
      },
      {
        Permission: 'Utilisateurs',
        Affectée: false,
      
      },
       {
        Permission: 'Dashbaord',
        Affectée: false,
        
      },
       {
        Permission: 'Catégories',
        Affectée: false,
        
      },
       {
        Permission: 'Paramètrage',
        Affectée: false,
        
      },
    ]

    return {
      permissionsData,
    }
  },
}
</script>
<style scoped>
i.code-toggler.feather.icon-code.cursor-pointer {
    display: none;
}
</style>
